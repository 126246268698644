import React from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { ReactComponent as MovieMuttsLogo } from '../images/movie-mutts-logo.svg';

export const initializeReactGA = () => {
    ReactGA.initialize('UA-34676164-1');
    ReactGA.pageview('/home');
};

export const logPageView = () => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
};

export default function Home() {
    initializeReactGA('UA-34676164-1', '/home');
    logPageView();

    return (
        <Wrapper>
            <InnerWrapper>
                <MovieMutts />
                {/* <MailchimpWrapper>
                    <h2>Can I let you know when I finish a new painting?</h2>
                    <Mailchimp
                        action="https://moviemutts.us4.list-manage.com/subscribe/post?u=b8a2e06d7722bc9d1024c84bf&amp;id=3e836b7036"
                        fields={[
                            {
                                name: 'EMAIL',
                                placeholder: 'Email',
                                type: 'email',
                                required: true,
                            },
                        ]}
                        messages={{
                            sending: 'Sending...',
                            success: 'Woof! Thanks for subscribing!',
                            error: 'An unexpected internal error has occurred.',
                            empty: 'You must write an e-mail.',
                            duplicate: 'Too many subscribe attempts for this email address',
                            button: 'Subscribe!',
                        }}
                    />
                </MailchimpWrapper> */}
            </InnerWrapper>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    align-items: center;
    align-self: center;
    display: flex;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    justify-content: center;
    padding: 20px;
    text-align: center;
    width: 100%;
`;

const InnerWrapper = styled.div`
    width: 100%;
`;

const MovieMutts = styled(MovieMuttsLogo)`
    width: 100%;
    max-width: 450px;
    max-height: 159px;
`;

// const Tagline = styled.p`
//     color: #4384b5;
//     font-weight: 600;
//     margin-top: 20px;
// `;

// const MailchimpWrapper = styled.div`
//     margin: 80px auto 30px auto;
//     h2 {
//         font-size: 15px;
//         font-style: italic;
//         font-weight: 500;
//         padding-bottom: 5px;
//         color: #555;
//         width: 170px;
//         margin: 0 auto;
//     }
//     input {
//         display: block;
//         font-family: 'raleway', sans-serif;
//         font-size: 15px;
//         font-weight: 500;
//         margin: 5px auto;
//         padding: 10px 10px 9px 10px;
//     }
//     button {
//         background-color: #dcdcdc;
//         border-radius: 20px;
//         border: 0;
//         font-family: 'raleway', sans-serif;
//         font-weight: 500;
//         margin-top: 5px;
//         outline: 0;
//         padding: 10px 20px;
//         &:hover {
//             background-color: #999;
//             cursor: pointer;
//         }
//     }
//     p {
//         font-style: italic;
//         font-weight: 600;
//         margin-top: 10px;
//     }
// `;
