//import libraries
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './Home';

// create a component
export default function Routing() {
    return (
        <Route
            render={() => (
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/home" component={Home} />
                </Switch>
            )}
        />
    );
}
