import React, { useState } from 'react';
import styled from 'styled-components';
import Routing from './pages/Routing';

import AppContext from './components/AppContext';

export default function App() {
    const [menu, setMenu] = useState(false);
    const [toggleOverlay, setToggleOverlay] = useState(false);

    const toggleMenu = () => {
        setMenu(!menu);
        setToggleOverlay(!toggleOverlay);
    };

    const userSettings = {
        menu,
        toggleOverlay,
        toggleMenu,
    };

    return (
        <AppContext.Provider value={userSettings}>
            <Wrapper>
                <Routing />
            </Wrapper>
        </AppContext.Provider>
    );
}

const Wrapper = styled.div`
    position: relative;
    padding: 0;
    background: var(--white);
    height: 100%;
    min-height: 100%;
    font-family: 'Raleway', sans-serif;
`;
